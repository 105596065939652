import { message, Modal } from "antd";
import React from "react";
import { addSubCategory, updateSubCategory } from "../../utils/api";
import CustomSelect from "../common/CustomSelect";
import CustomInput from "../common/Input";

const SubModal = ({
  modal,
  setModal,
  newSubCategory,
  setNewSubCategory,
  id,
  getSubCategoriesData,
  update,
  setUpdate,
}) => {
  const [buttonloading, setButtonLoading] = React.useState(false);

  const [messageApi, contextHolder] = message.useMessage();
  const createNewSubCategory = async () => {
    if (!newSubCategory.name || !newSubCategory.position)
      return messageApi.error("Please fill all the fields");
    setButtonLoading(true);
    if (!update) {
      const [success, response] = await addSubCategory({
        ...newSubCategory,
        categoryId: id,
      });
      if (success) {
        messageApi.success("Sub Category added successfully");
      }
    } else {
      const [success, response] = await updateSubCategory(
        update,
        newSubCategory
      );
      if (success) {
        messageApi.success("Sub Category updated successfully");
        setUpdate(null);
      }
    }
    getSubCategoriesData();
    setModal(false);
    setNewSubCategory({});
    setButtonLoading(false);
  };
  return (
    <Modal
      confirmLoading={buttonloading}
      onOk={createNewSubCategory}
      title={update ? "Update Sub Category" : "Add New Sub Category"}
      open={modal}
      onCancel={() => setModal(false)}
    >
      <div
        className="col"
        style={{
          marginTop: "10px",
        }}
      >
        <CustomInput
          name="Sub Category Name"
          onChange={(e) =>
            setNewSubCategory({ ...newSubCategory, name: e.target.value })
          }
          value={newSubCategory.name}
        />
        <CustomInput
          name="Sub Category Position"
          onChange={(e) =>
            setNewSubCategory({ ...newSubCategory, position: e.target.value })
          }
          value={newSubCategory.position}
        />
        <CustomSelect
          value={newSubCategory.imageType}
          onChange={(e) =>
            setNewSubCategory({
              ...newSubCategory,
              imageType: e,
            })
          }
          name="Select imageType"
          options={[
            { label: "Vertical", value: "Vertical" },
            { label: "Horizontal", value: "Horizontal" },
          ]}
        />
      </div>
    </Modal>
  );
};

export default SubModal;
