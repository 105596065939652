import { UploadOutlined } from "@ant-design/icons";
import { Button, Modal, Row, Typography, Upload } from "antd";
import React, { useEffect } from "react";
import CustomSelect from "../components/common/CustomSelect";
import CustomInput from "../components/common/Input";
import { createNewBanner, updateBanner } from "../utils/api";

const BannerModal = ({
  reload,
  categories,
  movies,
  series,
  messageApi,
  position,
  update,
  modal,
  setModal,
}) => {
  const [type, setType] = React.useState(undefined);

  const [buttonloading, setButtonLoading] = React.useState(false);
  const [newBanner, setNewBanner] = React.useState({});
  const [updatedImage, setUpdatedImage] = React.useState(undefined);
  const createBanner = async () => {
    if (!update) {
      if (
        newBanner.image === undefined ||
        newBanner.position === "" ||
        newBanner.id === "" ||
        newBanner.categoryId === ""
      ) {
        messageApi.error("Please fill all the fields");
        return;
      }
      setButtonLoading(true);

      const formData = new FormData();
      formData.append("image", newBanner.image);
      formData.append("horizontalBanner", newBanner.horizontalBanner);
      formData.append("position", newBanner.position);
      formData.append("id", newBanner.id);
      formData.append("type", type);
      formData.append("categoryId", newBanner.categoryId);
      formData.append("isEnable", true);
      const [success, response] = await createNewBanner(formData);
      if (success) {
        messageApi.success("Banner created successfully");
        setModal(false);
        reload();
      } else {
        messageApi.error(response.message);
      }
    } else {
      const formData = new FormData();
      if (newBanner.image !== update.image) {
        formData.append("image", newBanner.image);
      }

      if (newBanner.horizontalBanner !== update.horizontalBanner) {
        formData.append("horizontalBanner", newBanner.horizontalBanner);
      }

      formData.append("position", newBanner.position);
      formData.append("id", newBanner.id);
      formData.append("type", type);
      formData.append("categoryId", newBanner.categoryId);
      formData.append("isEnable", update.isEnable);
      const [success, response] = await updateBanner(update._id, formData);
      if (success) {
        messageApi.success("Banner updated successfully");
        setModal(false);
        reload();
      } else {
        messageApi.error(response.message);
      }
    }
    setButtonLoading(false);
  };

  useEffect(() => {
    if (update) {
      setNewBanner({
        id: update?.link?.item?._id,
        position: update?.position,
        categoryId: update?.category,
        image: update?.image,
      });

      setType(update?.link?.kind);
    }
  }, [update]);

  return (
    <>
      <Modal
        confirmLoading={buttonloading}
        title={update ? "Update Banner" : "Create New Banner"}
        open={modal}
        onCancel={() => setModal(false)}
        onOk={createBanner}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
          }}
        >
          <CustomInput
            name="Position"
            onChange={(e) =>
              setNewBanner((prev) => ({
                ...prev,
                position: e.target.value,
              }))
            }
            value={newBanner.position}
          />
          <CustomSelect
            name="Select Type"
            onChange={(e) => setType(e)}
            options={[
              {
                value: "movie",
                label: "Movies",
              },
              {
                value: "series",
                label: "Series",
              },
            ]}
            value={type}
          />
          <CustomSelect
            name="Select Category"
            onChange={(e) =>
              setNewBanner((prev) => ({
                ...prev,
                categoryId: e,
              }))
            }
            options={categories.map((item, index) => {
              return {
                value: item._id,
                label: item.name,
              };
            })}
            value={newBanner.categoryId}
          />
          {type && (
            <CustomSelect
              name={`Select ${type}`}
              onChange={(e) =>
                setNewBanner((prev) => ({
                  ...prev,
                  id: e,
                }))
              }
              options={
                type === "movie"
                  ? movies.map((item, index) => {
                      return {
                        value: item._id,
                        label: item.name,
                      };
                    })
                  : series.map((item, index) => {
                      return {
                        value: item._id,
                        label: item.name,
                      };
                    })
              }
              value={newBanner.id}
            />
          )}
          <Row justify={"space-between"}>
            <Typography.Text>Image</Typography.Text>
            <Upload
              onChange={(e) => {
                setNewBanner((prev) => ({
                  ...prev,
                  image: e.file.originFileObj,
                }));
              }}
            >
              <Button icon={<UploadOutlined />}>Click to Upload</Button>
            </Upload>
          </Row>
          <Row justify={"space-between"}>
            <Typography.Text>Horizontal Banner</Typography.Text>
            <Upload
              onChange={(e) => {
                setNewBanner((prev) => ({
                  ...prev,
                  horizontalBanner: e.file.originFileObj,
                }));
              }}
            >
              <Button icon={<UploadOutlined />}>Click to Upload</Button>
            </Upload>
          </Row>
          {update && (
            <img
              src={newBanner.image}
              alt="Banner"
              style={{
                width: "100px",
                height: "100px",
                objectFit: "contain",
              }}
            />
          )}
        </div>
      </Modal>
    </>
  );
};

export default BannerModal;
