import axios from "axios";

const URL = "https://woworiginals.in";

export const getDashboard = async () => {
  try {
    const response = await axios.get(`${URL}/admin/dashboard`);
    if (response.status === 200) return [true, response.data];
    else return [false, response.data];
  } catch (error) {
    return [false, error];
  }
};

export const getBanners = async () => {
  try {
    const response = await axios.get(`${URL}/admin/banners`);
    if (response.status === 200) return [true, response.data];
    else return [false, response.data];
  } catch (error) {
    return [false, error];
  }
};

export const getAllMovies = async () => {
  try {
    const response = await axios.get(`${URL}/movies`);
    if (response.status === 200) return [true, response.data];
    else return [false, response.data];
  } catch (error) {
    return [false, error];
  }
};
export const getAllSoon = async () => {
  try {
    const response = await axios.get(`${URL}/upcoming`);
    if (response.status === 200) return [true, response.data];
    else return [false, response.data];
  } catch (error) {
    return [false, error];
  }
};

export const updateSoon = async (id, data) => {
  try {
    const response = await axios.put(`${URL}/admin/upcoming/${id}`, data);

    if (response.status === 200) return [true, response.data];
    else return [false, response.data];
  } catch (error) {
    return [false, error];
  }
};

export const deleteSoon = async (id) => {
  try {
    const response = await axios.delete(`${URL}/admin/upcoming/${id}`);

    if (response.status === 200) return [true, response.data];
    else return [false, response.data];
  } catch (error) {
    return [false, error];
  }
};

export const addSoon = async (data) => {
  try {
    const response = await axios.post(`${URL}/admin/upcoming`, data);
    if (response.status === 200) return [true, response.data];
    else return [false, response.data];
  } catch (error) {
    return [false, error];
  }
};

export const getAllSeries = async () => {
  try {
    const response = await axios.get(`${URL}/series`);
    if (response.status === 200) return [true, response.data];
    else return [false, response.data];
  } catch (error) {
    return [false, error];
  }
};

export const getAllCategories = async () => {
  try {
    const response = await axios.get(`${URL}/categories`);
    if (response.status === 200) return [true, response.data];
    else return [false, response.data];
  } catch (error) {
    return [false, error];
  }
};

export const createNewBanner = async (data) => {
  try {
    const response = await axios.post(`${URL}/admin/banner`, data);
    if (response.status === 200) return [true, response.data];
    else return [false, response.data];
  } catch (error) {
    return [false, error];
  }
};

export const updateBanner = async (id, data) => {
  try {
    const response = await axios.put(`${URL}/admin/banner/${id}`, data);
    if (response.status === 200) return [true, response.data];
    else return [false, response.data];
  } catch (error) {
    return [false, error];
  }
};

export const deleteBanner = async (id) => {
  try {
    const response = await axios.delete(`${URL}/admin/banner/${id}`);
    if (response.status === 200) return [true, response.data];
    else return [false, response.data];
  } catch (error) {
    return [false, error];
  }
};

export const addCategory = async (data) => {
  try {
    const response = await axios.post(`${URL}/admin/category`, data);
    if (response.status === 200) return [true, response.data];
    else return [false, response.data];
  } catch (error) {
    return [false, error];
  }
};

export const updateCategory = async (id, data) => {
  try {
    const response = await axios.put(`${URL}/admin/category/${id}`, data);
    if (response.status === 200) return [true, response.data];
    else return [false, response.data];
  } catch (error) {
    return [false, error];
  }
};

export const deleteCategory = async (id) => {
  try {
    const response = await axios.delete(`${URL}/admin/category/${id}`);
    if (response.status === 200) return [true, response.data];
    else return [false, response.data];
  } catch (error) {
    return [false, error];
  }
};

export const getSubCategories = async (id) => {
  try {
    const response = await axios.get(`${URL}/category/${id}/subcategory`);
    if (response.status === 200) return [true, response.data];
    else return [false, response.data];
  } catch (error) {
    return [false, error];
  }
};

export const addSubCategory = async (data) => {
  try {
    const response = await axios.post(`${URL}/admin/subcategory`, data);
    if (response.status === 200) return [true, response.data];
    else return [false, response.data];
  } catch (error) {
    return [false, error];
  }
};

export const updateSubCategory = async (id, data) => {
  try {
    const response = await axios.put(`${URL}/admin/subcategory/${id}`, data);
    if (response.status === 200) return [true, response.data];
    else return [false, response.data];
  } catch (error) {
    return [false, error];
  }
};

export const deleteSubCategory = async (id) => {
  try {
    const response = await axios.delete(`${URL}/admin/subcategory/${id}`);
    if (response.status === 200) return [true, response.data];
    else return [false, response.data];
  } catch (error) {
    return [false, error];
  }
};

export const addMovietoSubCategory = async (data) => {
  try {
    const response = await axios.put(
      `${URL}/admin/subcategory/add-movie`,
      data
    );
    if (response.status === 200) return [true, response.data];
    else return [false, response.data];
  } catch (error) {
    return [false, error];
  }
};

export const addSeriesToSubCategory = async (data) => {
  try {
    const response = await axios.put(
      `${URL}/admin/subcategory/add-series`,
      data
    );
    if (response.status === 200) return [true, response.data];
    else return [false, response.data];
  } catch (error) {
    return [false, error];
  }
};

export const removeMovieFromSubCategory = async (data) => {
  try {
    const response = await axios.put(
      `${URL}/admin/subcategory/remove-movie`,
      data
    );
    if (response.status === 200) return [true, response.data];
    else return [false, response.data];
  } catch (error) {
    return [false, error];
  }
};

export const removeSeriesFromSubCategory = async (data) => {
  try {
    const response = await axios.put(
      `${URL}/admin/subcategory/remove-series`,
      data
    );
    if (response.status === 200) return [true, response.data];
    else return [false, response.data];
  } catch (error) {
    return [false, error];
  }
};

export const changeSeriesPosition = async (data) => {
  try {
    const response = await axios.put(
      `${URL}/admin/subcategory-update/series-position`,
      data
    );
    if (response.status === 200) return [true, response.data];
    else return [false, response.data];
  } catch (error) {
    return [false, error];
  }
};

export const changeMoviePosition = async (data) => {
  try {
    const response = await axios.put(
      `${URL}/admin/subcategory-update/movie-position`,
      data
    );
    if (response.status === 200) return [true, response.data];
    else return [false, response.data];
  } catch (error) {
    return [false, error];
  }
};

export const getAllGenres = async () => {
  try {
    const response = await axios.get(`${URL}/admin/genres`);
    if (response.status === 200) return [true, response.data];
    else return [false, response.data];
  } catch (error) {
    return [false, error];
  }
};
export const getAllTransaction = async () => {
  try {
    const response = await axios.get(`${URL}/admin/transactions`);
    if (response.status === 200) return [true, response.data];
    else return [false, response.data];
  } catch (error) {
    return [false, error];
  }
};
export const getAllActiveSubs = async () => {
  try {
    const response = await axios.get(`${URL}/admin/active-subscriptions`);
    if (response.status === 200) return [true, response.data];
    else return [false, response.data];
  } catch (error) {
    return [false, error];
  }
};

export const addGenre = async (data) => {
  try {
    const response = await axios.post(`${URL}/admin/genre`, data);
    if (response.status === 200) return [true, response.data];
    else return [false, response.data];
  } catch (error) {
    return [false, error];
  }
};
export const updateGenre = async (id, data) => {
  try {
    const response = await axios.put(`${URL}/admin/genre/${id}`, data);
    if (response.status === 200) return [true, response.data];
    else return [false, response.data];
  } catch (error) {
    return [false, error];
  }
};

export const addMovie = async (data) => {
  try {
    const response = await axios.post(`${URL}/admin/movie`, data);
    if (response.status === 200) return [true, response.data];
    else return [false, response.data];
  } catch (error) {
    return [false, error];
  }
};

export const deleteMovie = async (id) => {
  try {
    const response = await axios.delete(`${URL}/admin/movie/${id}`);

    if (response.status === 200) return [true, response.data];
    else return [false, response.data];
  } catch (error) {
    return [false, error];
  }
};

export const updateMovie = async (id, data) => {
  try {
    const response = await axios.put(`${URL}/admin/movie/${id}`, data);

    if (response.status === 200) return [true, response.data];
    else return [false, response.data];
  } catch (error) {
    return [false, error];
  }
};

export const addSeries = async (data) => {
  try {
    const response = await axios.post(`${URL}/admin/series`, data);
    if (response.status === 200) return [true, response.data];
    else return [false, response.data];
  } catch (error) {
    return [false, error];
  }
};

export const deleteSeries = async (id) => {
  try {
    const response = await axios.delete(`${URL}/admin/series/${id}`);

    if (response.status === 200) return [true, response.data];
    else return [false, response.data];
  } catch (error) {
    return [false, error];
  }
};

export const updateSeries = async (id, data) => {
  try {
    const response = await axios.put(`${URL}/admin/series/${id}`, data);

    if (response.status === 200) return [true, response.data];
    else return [false, response.data];
  } catch (error) {
    return [false, error];
  }
};

export const getSeriesById = async (id) => {
  try {
    const response = await axios.get(`${URL}/series/${id}`);
    if (response.status === 200) return [true, response.data];
    else return [false, response.data];
  } catch (error) {
    return [false, error];
  }
};

export const addEpisode = async (data) => {
  try {
    const response = await axios.post(`${URL}/admin/series/add-episode`, data);
    if (response.status === 200) return [true, response.data];
    else return [false, response.data];
  } catch (error) {
    return [false, error];
  }
};

export const updateEpisode = async (data) => {
  try {
    const response = await axios.put(
      `${URL}/admin/series/update-episode`,
      data
    );
    if (response.status === 200) return [true, response.data];
    else return [false, response.data];
  } catch (error) {
    return [false, error];
  }
};

export const deleteEpisode = async (data) => {
  try {
    const response = await axios.delete(`${URL}/admin/series/delete-episode`, {
      data: data,
    });
    if (response.status === 200) return [true, response.data];
    else return [false, response.data];
  } catch (error) {
    return [false, error];
  }
};

export const getAllUsers = async (page, limit) => {
  try {
    const response = await axios.get(
      `${URL}/admin/users?page=${page}&limit=${limit}`
    );
    if (response.status === 200) return [true, response.data];
    else return [false, response.data];
  } catch (error) {
    return [false, error];
  }
};

export const addUser = async (data) => {
  try {
    const response = await axios.post(`${URL}/admin/user`, data);
    if (response.status === 200) return [true, response.data];
    else return [false, response.data];
  } catch (error) {
    return [false, error];
  }
};

export const deleteUser = async (id) => {
  try {
    const response = await axios.delete(`${URL}/admin/user/${id}`);

    if (response.status === 200) return [true, response.data];
    else return [false, response.data];
  } catch (error) {
    return [false, error];
  }
};

export const updateUser = async (id, data) => {
  try {
    const response = await axios.put(`${URL}/admin/user/${id}`, data);

    if (response.status === 200) return [true, response.data];
    else return [false, response.data];
  } catch (error) {
    return [false, error];
  }
};
export const getUserNotication = async (id) => {
  try {
    const response = await axios.get(`${URL}/admin/user-notifications/${id}`);
    if (response.status === 200) return [true, response.data];
    else return [false, response.data];
  } catch (error) {
    return [false, error];
  }
};

export const addUserNotification = async (data) => {
  try {
    const response = await axios.post(`${URL}/admin/notification`, data);
    if (response.status === 200) return [true, response.data];
    else return [false, response.data];
  } catch (error) {
    return [false, error];
  }
};

export const loginAdmin = async (data) => {
  try {
    const response = await axios.post(`${URL}/admin/login`, data);
    if (response.status === 200) return [true, response.data];
    else return [false, response.data];
  } catch (error) {
    return [false, error];
  }
};

export const updateUserImage = async (id, data) => {
  try {
    const response = await axios.put(`${URL}/admin/user-image/${id}`, data);

    if (response.status === 200) return [true, response.data];
    else return [false, response.data];
  } catch (error) {
    return [false, error];
  }
};

export const addCastToMovie = async (id, data) => {
  try {
    const response = await axios.put(`${URL}/admin/add-movie-cast/${id}`, data);
    if (response.status === 200) return [true, response.data];
    else return [false, response.data];
  } catch (error) {
    return [false, error];
  }
};

export const addCastToSeries = async (id, data) => {
  try {
    const response = await axios.put(
      `${URL}/admin/add-series-cast/${id}`,
      data
    );
    if (response.status === 200) return [true, response.data];
    else return [false, response.data];
  } catch (error) {
    return [false, error];
  }
};

export const addSubscribtion = async (data) => {
  try {
    const response = await axios.post(`${URL}/admin/subscription`, data);
    if (response.status === 200) {
      return [true, response.data];
    }
    return [false, response.data];
  } catch (err) {
    return [false, err];
  }
};
export const updateUserSubscription = async (data, id) => {
  try {
    const response = await axios.put(`${URL}/admin/user-subscription`, data);
    if (response.status === 200) {
      return [true, response.data];
    }
    return [false, response.data];
  } catch (err) {
    return [false, err];
  }
};

export const updateSubscription = async (data, id) => {
  try {
    const response = await axios.put(`${URL}/admin/subscription/${id}`, data);
    if (response.status === 200) {
      return [true, response.data];
    }
    return [false, response.data];
  } catch (err) {
    return [false, err];
  }
};
export const getAllSubscribtions = async () => {
  try {
    const response = await axios.get(`${URL}/all-subscriptions`);
    if (response.status === 200) {
      return [true, response.data];
    }
    return [false, response.data];
  } catch (err) {
    return [false, err];
  }
};

export const subscribtionsCheckMovie = async (id) => {
  try {
    const response = await axios.get(`${URL}/admin/subscription-movie/${id}`);
    if (response.status === 200) {
      return [true, response.data];
    }
    return [false, response.data];
  } catch (err) {
    return [false, err];
  }
};
export const subscribtionsCheckSeries = async (id) => {
  try {
    const response = await axios.get(`${URL}/admin/subscription-series/${id}`);
    if (response.status === 200) {
      return [true, response.data];
    }
    return [false, response.data];
  } catch (err) {
    return [false, err];
  }
};
export const addDataToSubcription = async (data) => {
  try {
    const response = await axios.put(
      `${URL}/admin/subscription/add-video`,
      data
    );
    if (response.status === 200) {
      return [true, response.data];
    }
    return [false, response.data];
  } catch (err) {
    return [false, err];
  }
};
export const deleteSubscription = async (id) => {
  try {
    const response = await axios.delete(`${URL}/admin/subscription/${id}`);
    if (response.status === 200) {
      return [true, response.data];
    }
    return [false, response.data];
  } catch (err) {
    return [false, err];
  }
};
export const getAllUserRent = async (id) => {
  try {
    const response = await axios.get(`${URL}/admin/user-rent/${id}`);
    console.log(response);
    if (response.status === 200) {
      return [true, response.data];
    }
    return [false, response.data];
  } catch (err) {
    return [false, err];
  }
};
export const addDatatoRent = async (data) => {
  try {
    const response = await axios.post(`${URL}/admin/user-add-rent`, data);
    if (response.status === 200) {
      return [true, response.data];
    }
    return [false, response.data];
  } catch (err) {
    return [false, err];
  }
};

export const getSystemValues = async () => {
  try {
    const response = await axios.get(`${URL}/system-values`);
    if (response.status === 200) return [true, response.data];
    else return [false, response.data];
  } catch (error) {
    return [false, error];
  }
};

export const updateSystemValues = async (data) => {
  try {
    const response = await axios.put(`${URL}/admin/system-values`, data);
    if (response.status === 200) return [true, response.data];
    else return [false, response.data];
  } catch (error) {
    return [false, error];
  }
};

export const userSubHistory = async (id) => {
  try {
    const response = await axios.get(
      `${URL}/admin/user-subscription-history/${id}`
    );
    if (response.status === 200) return [true, response.data];
    else return [false, response.data];
  } catch (err) {
    return [false, err];
  }
};

export const removeMovieCast = async (data) => {
  try {
    const response = await axios.put(`${URL}/admin/remove-movie-cast`, data);
    if (response.status === 200) return [true, response.data];
    else return [false, response.data];
  } catch (err) {
    return [false, err];
  }
};
export const removeSeriesCast = async (data) => {
  try {
    const response = await axios.put(`${URL}/admin/remove-series-cast`, data);
    if (response.status === 200) return [true, response.data];
    else return [false, response.data];
  } catch (err) {
    return [false, err];
  }
};

export const userSerach = async (data) => {
  try {
    const response = await axios.post(`${URL}/admin/user-search`, data);
    if (response.status === 200) return [true, response.data];
    else return [false, response.data];
  } catch (err) {
    return [false, err];
  }
};

export const addPoster = async (data) => {
  try {
    const response = await axios.post(`${URL}/admin/singlebanner`, data);
    if (response.status === 200) return [true, response.data];
    else return [false, response.data];
  } catch (err) {
    console.log(err);
    return [false, err];
  }
};

export const getPoster = async () => {
  try {
    const response = await axios.get(`${URL}/single-banner`);
    if (response.status === 200) return [true, response.data];
    else return [false, response.data];
  } catch (err) {
    console.log(err);
    return [false, err];
  }
};

export const deletePoster = async () => {
  try {
    const response = await axios.delete(`${URL}/admin/singlebanner`);
    if (response.status === 200) return [true, response.data];
    else return [false, response.data];
  } catch (err) {
    console.log(err);
    return [false, err];
  }
};

export const updatePoster = async (data) => {
  try {
    const response = await axios.put(`${URL}/admin/singlebanner`, data);
    if (response.status === 200) return [true, response.data];
    else return [false, response.data];
  } catch (err) {
    console.log(err);
    return [false, err];
  }
};

export const deleteUpcoming = async (id) => {
  try {
    const response = await axios.delete(`${URL}/admin/upcoming/${id}`);
    if (response.status === 200) return [true, response.data];
    else return [false, response.data];
  } catch (err) {
    console.log(err);
    return [false, err];
  }
};
